<template>
  <v-app>
    <v-overlay :value="overlay">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <ToolBar />
    <v-main>
      <router-view
        v-on:show-snackbar="showSnackbar"
        @showOverlay="showOverlay"
        @showAlert="showAlert"
      />
    </v-main>
    <v-snackbar v-model="snackbar.visible" :timeout="1000">
      {{ snackbar.text }}
      <v-btn @click="snackbar.visible = false" color="teal" text>Cerrar</v-btn>
    </v-snackbar>
    <v-alert
      class="ml-3 mr-3 alertShow"
      v-show="alert.visible"
      type="success"
      >{{ alert.text }}</v-alert
    >
  </v-app>
</template>
<script>
import ToolBar from "../components/ToolBar/toolBar";

export default {
  name: "App",

  components: {
    ToolBar,
  },

  data: () => ({
    //
    overlay: false,
    alert: {
      visible: false,
      text: "",
    },
    snackbar: {
      visible: false,
      text: "",
    },
  }),
  methods: {
    // Bienvenido, Un gusto verte por aqui otra vez
    showSnackbar(message) {
      this.snackbar.visible = true;
      this.snackbar.text = message;
    },
    showOverlay(overlay) {
      this.overlay = overlay;
    },
    showAlert(message, visible) {
      this.alert.visible = visible;
      this.alert.text = message;
    },
  },
};
</script>
<style lang="css">
.alertShow {
  width: 40% !important;
}
@media handheld, only screen and (max-width: 462px) {
  .alertShow {
    width: 100% !important;
  }
}
</style>
