<template>
  <div>
    <CarDialog v-if="user != null" v-show="dialog" />
    <v-toolbar flat app>
      <v-app-bar-nav-icon
        @click="openNavigation()"
        class="grey--text hidden-md-and-up"
      ></v-app-bar-nav-icon>
      <v-app-bar-nav-icon
        @mouseover="mouseOver"
        @click="openNavigation()"
        class="grey--text hidden-sm-and-down"
      ></v-app-bar-nav-icon>
      <v-toolbar-title
        @click="toHome()"
        class="titleTextMovil hidden-md-and-up"
        v-if="user == null"
      >
        Sadaii
      </v-toolbar-title>
      <v-toolbar-title
        v-if="user != null"
        @click="toHome()"
        class="text-bold hidden-md-and-up"
        >{{ user.nameStore }}</v-toolbar-title
      >
      <v-toolbar-title @click="toHome()" class="titleText hidden-sm-and-down">{{
        user == null ? "Sadaii" : user.nameStore
      }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn class="btn " v-show="$route.path != '/profile'" text to="/login">
        {{ user == null ? "Mi cuenta" : "Mi Cuenta" }}
      </v-btn>
      <v-btn to="/healp" class="btn hidden-sm-and-down " text>AYUDA</v-btn>

      <v-badge :content="carCount" :value="carCount" color="red" overlap>
        <v-icon large @click.stop="toggleDrawer">
          shopping_cart
        </v-icon>
      </v-badge>
    </v-toolbar>
    <div @mouseleave="mouseOut">
      <v-navigation-drawer
        width="350"
        app
        permanent
        v-if="drawer"
        class="white navigationDrawer accent-4"
      >
        <div>
          <img
            src="../../assets/cancel.png"
            height="20"
            width="20"
            @click="drawer = false"
            class="hidden-md-and-up"
          />

          <label class="titleTextNav  ml-5 ">
            Sadaii
          </label>
        </div>
        <div class="containerOption">
          <div v-for="(item, index) in ComputedMenus" :key="index">
            <label
              class="categoriesTitle"
              @click="colapseChild(categories, item)"
              >{{ item.title }}</label
            >

            <div
              v-for="(subItem, i) in item.subCategories"
              :key="i"
              v-if="item.show"
            >
              <label
                v-if="subItem.subCategories.length"
                class="h5 ml-3"
                :class="atribute(subItem)"
                @click="colapseChild(item.subCategories, subItem)"
                >{{ subItem.title }}</label
              >

              <label
                v-if="subItem.subCategories.length === 0"
                class="h5 ml-3"
                :class="atribute(subItem)"
                @click="to(item, subItem.title)"
                >{{ subItem.title }}</label
              >

              <div
                v-if="subItem.show"
                v-for="(category, ind) in subItem.subCategories"
                :key="ind"
              >
                <label
                  @click="to(subItem, category)"
                  class="subCategory ml-6"
                  >{{ category }}</label
                >
              </div>
            </div>
          </div>
        </div>
        <label class="healp " @click="goToHealp" text>AYUDA</label>

        <div class="containerBtnDrawer">
          <label @click="toHome" class="initText">INICIO</label>
          <label
            class="myAccount "
            v-show="$route.path != '/profile'"
            text
            @click="goToLogin"
          >
            {{ user == null ? "MI CUENTA" : "MI CUENTA" }}
          </label>
        </div>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CarDialog from "../Car/CarDialog";
export default {
  components: {
    CarDialog,
  },
  data() {
    return {
      drawer: false,
      visibility: false,
      dialog: false,
    };
  },
  mounted() {
    this.loadCategory();
  },
  computed: {
    ...mapGetters(["user", "menus", "carCount"]),
    ComputedMenus() {
      return this.menus.reverse();
    },
  },
  methods: {
    ...mapActions(["getMenus"]),
    toggleDrawer() {
      this.$root.$emit("toggle-drawer");
    },
    openNavigation() {
      this.drawer = true;
    },
    loadCategory() {
      this.getMenus();
    },
    toHome() {
      this.$router.push("/").catch((err) => {});
      this.drawer = false;
    },
    goToLogin() {
      this.$router.push("/login").catch((err) => {});
      this.drawer = false;
    },
    goToHealp() {
      this.$router.push("/healp").catch((err) => {});
      this.drawer = false;
    },
    to(object, path) {
      if (object.category === undefined) {
        var item = {
          category: object.title,
          subCategory: "",
          items: [],
          categorySelect: path,
        };
      } else {
        var item = {
          category: object.category,
          subCategory: object.title,
          items: object.subCategories,
          categorySelect: path,
        };
      }
      this.drawer = false;
      this.$router.push("/product/" + JSON.stringify(item)).catch((err) => {});
    },
    mouseOver: function() {
      this.drawer = !this.drawer;
    },
    mouseIn: function() {
      this.drawer = true;
    },
    mouseOut: function() {
      this.drawer = false;
    },
    colapseChild(baseCollection, item) {
      item.show = !item.show;
      baseCollection.forEach((element) => {
        if (element != item) {
          element.show = false;
        }
      });
    },
    atribute(item) {
      return {
        "font-weight-bold": item.show,
      };
    },
  },
};
</script>
<style scoped>
.titleText {
  margin-left: 2%;
  font-size: 4em;
  font-weight: bold;
  cursor: pointer;
}
.titleTextMovil {
  margin-left: 2%;
  font-size: 3em;
  font-weight: bold;
  cursor: pointer;
}
.titleTextNav {
  font-size: 4em;
  font-weight: bold;
  color: #f5f5f5;
  cursor: pointer;
}
@media handheld, only screen and (max-width: 463px) {
  .btn {
    font-size: 9px !important;
  }
  .navigationDrawer {
    padding-top: 100px;
    padding-left: 20px;
    width: 270px !important;
  }

  .categoriesTitle {
    font-weight: bold !important;
    font-family: "arial";
  }
}
.categoriesTitle {
  font-size: 1.5em;
  font-weight: bold !important;
  font-family: "arial";
}
.categoriesTitle:hover {
  cursor: pointer;
}
.subCategories {
  font-size: 1.5em;
}
.subCategories:hover {
  cursor: pointer;
}

.subCategory {
  font-size: 1em;
  margin-left: 2px;
}
.subCategory:hover {
  cursor: pointer;
}
.navigationDrawer {
  padding-top: 10px;
  padding-left: 20px;
}
.containerBtnDrawer {
  margin-top: 30%;
}
.containerOption {
  padding-top: 50px;
}
.myAccount {
  font-size: 1.2em;
  font-weight: bold !important;
  font-family: "arial";
  margin-left: 20%;
}
/* .containerTexEnd {
  margin-top: 100%;
} */
.initText {
  font-size: 1.2em;
  font-weight: bold !important;
  font-family: "arial";
}
.healp {
  font-size: 1.2em;
  font-weight: bold !important;
  font-family: "arial";
  margin-top: 40%;
}
.myAccount:hover {
  cursor: pointer;
}
.healp:hover {
  cursor: pointer;
}
.initText:hover {
  cursor: pointer;
}
</style>
